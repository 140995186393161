




























import defaultComponent from "../utils/defaultComponent";
import moment from "moment";
import stateManager from "../../../services/stateManager";

export default defaultComponent.apply({
  props: ["value", "label"],
  data() {
    return {
      activePicker: null,
      menu: false,
      dpValue: null,
      timeValue: null,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = this.options.content.dpAttrs.type === "month" ? "MONTH" : "DATE"));
    },
    value(value) {
      this.setValue(value);
    },
  },
  methods: {
    initialize() {
      this.setValue(this.value);
    },
    reset() {
      this.setValue(0);
      this.$emit("input", 0);
    },
    setValue(value) {
      if (value && value === this.timeValue) {
        return;
      }
      let changed = false;
      if (!value && this.options.content.setDefaultValue) {
        value = new Date().getTime();
        changed = true;
      }
      this.timeValue = value;

      if (value) {
        {
          const currentTimeZoneOffset = stateManager.get("currentTimeZoneOffset");
          if (!isNaN(parseInt(currentTimeZoneOffset, 10))) {
            value -= new Date().getTimezoneOffset() * 60 * 1000;
            value -= currentTimeZoneOffset;
          }
        }
        this.dpValue = moment(value).format(this.options.content.format);
      } else {
        this.dpValue = "";
      }

      if (changed) {
        this.$emit("input", this.timeValue);
      }
    },
    setDpValue(dateStr) {
      this.dpValue = dateStr;
      let value = moment(dateStr).valueOf();
      {
        const currentTimeZoneOffset = stateManager.get("currentTimeZoneOffset");
        if (!isNaN(parseInt(currentTimeZoneOffset, 10))) {
          value -= new Date().getTimezoneOffset() * 60 * 1000;
          value -= currentTimeZoneOffset;
        }
      }
      let m = moment(value);
      if (this.options.content.resultFilter) {
        m = this.options.content.resultFilter(m);
      }
      this.timeValue = m.valueOf();
      this.$emit("input", this.timeValue);
    },
  },
  extraOptions: {
    defaultClass: "dateTimePicker",
  },
});
